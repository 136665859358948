import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';


const cache = setupCache({
    maxAge: 5000
})

const api = axios.create({
    adapter: cache.adapter
})

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get List of Worlds Countrys 
 * @param {*} token 
 * @returns Promise
 */
const getCountWCountry = (token) => {

    return new Promise((resolve, reject) => {
        api({
            url: baseURL + `/getCountWCountry?token=${token}`,
            method: "get"
        }).then(({ data }) => {
            const dataRes = data.data;
            if (data.error) reject(data.error)

            resolve(dataRes);
        }).catch((e) => {
            reject(e.message);
        })
    })
}

export {
    getCountWCountry
}