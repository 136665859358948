import React, { useState, useEffect } from "react"

import Header from "./Header";
import Footer from "./Footer";

export default function PageNotFound() {
    return (
        <>
            <div className="l-content-outer">
                <div className="l-content-wrap">
                    {/* BEGIN NOINDEX */}
                    <ul className="breadcrumbs" role="navigation" aria-label="Breadcrumb Links" vocab="https://schema.org/" typeof="BreadcrumbList">
                        <li className="breadcrumbs__item breadcrumbs__item--parent" property="itemListElement" typeof="ListItem">
                            <a href="/" className="breadcrumbs__link" property="item" typeof="WebPage"><span property="name">MUSC Home</span></a>
                            <meta property="position" content={1} />
                        </li>
                        <li className="breadcrumbs__item">
                            404
                        </li>
                    </ul>
                    {/* END NOINDEX */}
                </div>
                <div className="l-content-wrap">
                    <div className="l-1-col-master">
                        <div className="l-contain">
                            <main id="main" className="l-content-primary" aria-labelledby="page-h1">
                                <h1 id="page-h1" className="long-title">We're sorry. We can't find that page.</h1>	{/*sc:404*/}
                                <div className="panel-collapse l-three-col">
                                    <div className="rtf u-cf js-no-paste">
                                        <p>The page you are looking for has either moved or is not available (error 404).</p>
                                        <p>Please return to the <a href="/">homepage</a> or <a href="/search">search our site</a> to see if the page is available in a different location.</p>
                                        <h2>Popular Pages</h2>
                                        <div className="column-row">
                                            <div className="two-col-snippet">
                                                <ul>
                                                    <li><a href="/about">About MUSC</a></li>
                                                    <li><a href="https://education.musc.edu/">Colleges &amp; Academic Programs</a></li>
                                                    <li><a href="https://giving.musc.edu/">Ways to Give</a></li>
                                                    <li><a href="https://research.musc.edu/">Research Initiatives</a></li>
                                                </ul>
                                            </div>
                                            <div className="two-col-snippet">
                                                <ul>
                                                    <li><a href="/human-resources">Careers</a></li>
                                                    <li><a href="https://muschealth.org/">MUSC Health Patient Information</a></li>
                                                    <li><a href="https://muschealth.org/MUSCApps//providerdirectory/providers.aspx">Find a Provider</a></li>
                                                    <li><a href="https://muschealth.org/health-professionals/refer">Referring Physicians</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p>If you think this message is in error or need assistance, please <a href="mailto:webcms@musc.edu?subject=Problem%20with%20MUSC%20website:%20https://web.musc.edu/about/global-health/researchsg%20we">email the webmaster</a>.</p>
                                    </div>
                                </div>
                            </main>
                        </div>{/* .l-contain */}
                    </div>{/* .l-1-col-master */}
                </div>{/* .l-content-wrap */}
            </div>
        </>

    )

}