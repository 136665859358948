import { useLocation, Link } from "react-router-dom";


const Breadcrumbs = ({customTitle}) => {

    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);




    
    return (
        <div>
            <a href="https://web.musc.edu/" style={{fontSize: "0.95rem", textDecoration: "underline"}}>MUSC Home</a><span> &gt; </span>
            <a href="/" style={{fontSize: "0.95rem", textDecoration: "underline"}}>Our Global Health Impact - Map Home</a>
            {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                const isLast = index === pathnames.length - 1;
                (name == "projects")? name = "Projects Database": name = name;
                
                isLast? name= (customTitle ?? name): name = name;
                return isLast ? (
                    <span key={name} style={{fontSize: "0.95rem"}}> &gt; {name}</span>
                ) : (
                    <>
                        <span> &gt; </span>
                        <a key={name} href={routeTo} style={{fontSize: "0.95rem", textDecoration: "underline"}}>
                            {name}
                        </a>
                    </>
                );
            })}
        </div>
    );
}  

export default Breadcrumbs;