import { Button, TableRow, TableCell } from "@material-ui/core";
import { approveStatus } from "./APIsClient";

const unreviewFormList = ({obj, k, deleteProject}) => {

      // Issue 1
    const approve = (e) => {
        let id = e.currentTarget.getAttribute("val");
        approveStatus(id).then(data=>{
          $("#" + id).remove();
        }).catch(e=>{
          console.log("something went wrong", e);
        })
    };


    return (
          <TableRow id={obj._id} key={k}>
            <TableCell>{obj.Project}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                val={obj._id}
                onClick={approve}
                style={{
                  color: "white",
                  backgroundColor: "rgb(0, 117, 158)",
                }}
              >
                Approve
              </Button>
            </TableCell>
            <TableCell>
              <a href={"/admin/editform/" + obj._id}>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(0, 117, 158)",
                  }}
                >
                  Edit
                </Button>
              </a>
            </TableCell>
            <TableCell>
              
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(0, 117, 158)",
                  }}
                  onClick={() => deleteProject(obj._id)}
                >
                  Delete
                </Button>
            </TableCell>
          </TableRow>
    );
  };


export default unreviewFormList;