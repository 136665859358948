import React from 'react';
import AdminPage from '../components/Admin/AdminPage';
export default function AdminScreen() {
    return (
        <AdminPage />
    );

}





