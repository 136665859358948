import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';


var cache = setupCache({
    maxAge: 5000 * 10
})

var api = axios.create({
    // adapter: cache.adapter
})

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get Info of Project By URL
 * @param {String} url url of Project
 * 
 * @returns project object {Associated_Faculty: [], Global_Health_Topic: [], Dates: "", Project: "", Description: "", countries: [], loaded: false,}
 */

const searchProject = (query) => {
    return new Promise((resolve, reject) => {
        api({
            url: `${baseURL}/getProjects?token=${0}&query=${query}`,
            method: 'get',
        }).then(({ data }) => {
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}
const getProject = (url) => {
    return new Promise((resolve, reject) => {
        api({
            url: `${baseURL}/getProject?token=${0}&url=${url}`,
            method: 'get',
        }).then(({ data }) => {
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}
const getProjects = () => {
    return new Promise((resolve, reject) => {
        api({
            url: `${baseURL}/getProjects?token=${1}`,
            method: 'get',
        }).then(({ data }) => {
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}

//Display

var cache = setupCache({
    maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
var api = axios.create({
    // adapter: cache.adapter,
});

/**
 * 
 * @param {String} country Name of Country | all
 * @param {String} faculty Name of Faculty | all
 * @param {String} topic Name of Topic | all
 * @returns 
 */

const findProjects = (country, faculty, topic, page = 1) => {
    return new Promise((resolve, reject) => {
        api({
            url: `${baseURL}/findProjects?
            token=${1}&
            country=${encodeURIComponent(country)}&
            faculty=${encodeURIComponent(faculty)}&
            topic=${encodeURIComponent(topic)}&page=${page}`,
            method: "get"

        }).then(({ data }) => {
            if (data.error) reject(data.error);
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}

const getJson = () => {
    return new Promise((resolve, reject) => {
        api({
            url: baseURL + `/getJson?token=${1}`,
            method: "get"
        }).then(({ data }) => {
            if (data.error) reject(data.error);
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}

const getFaculties = () => {
    return new Promise((resolve, reject) => {
        api({
            url: baseURL + `/getFaculties?token=${1}`,
            method: "get"
        }).then(({ data }) => {
            if (data.error) reject(data.error);
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}

const getTopics = () => {
    return new Promise((resolve, reject) => {
        api({
            url: baseURL + `/getTopics?token=${1}`,
            method: "get"
        }).then(({ data }) => {
            if (data.error) reject(data.error);
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}

const getTypes = () => {
    return new Promise((resolve, reject) => {
        api({
            url: baseURL + `/getTypes?token=${1}`,
            method: "get"
        }).then(({ data }) => {
            if (data.error) reject(data.error);
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}

const findProjectsByQuery = (Query,page=1) => {
    return new Promise((resolve, reject) => {
        api({
            url: baseURL + `/findProjects?token=${1}${Query}page=${page}`,
            method: "get"
        }).then(({ data }) => {
            if (data.error) reject(data.error);
            resolve(data);
        }).catch(e => {
            reject(e.message);
        })
    })
}
export {
    searchProject,
    getProject,
    getProjects,
    findProjects,
    getFaculties,
    getJson,
    getTopics,
    getTypes,
    findProjectsByQuery
}


