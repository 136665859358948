import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { isExpired, decodeToken,  } from "react-jwt";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
var cookies;

const SideMenu = () => {

    const [ dataCookies, setDataCookies ] = useState({});
    const logoutUser = () => {
      Cookies.remove("jwt");
      window.location.href = "/logout";
  }
    useEffect(() => {
        try{
          cookies= Cookies.get("jwt");
          setDataCookies(decodeToken(cookies));
          if(isExpired(cookies)) throw new Error("Expired Token");
        }catch(err){
          window.location.href= "/login";
        }
      }, []);

    return (  
        <>
            <div
          style={{ margin: "11px auto 11px 0", paddingTop: "60px"}}>
          {/* need to show a icon and username */}
          <div style={{textAlign: "center", width: "170px"}}>
            {/* <PersonIcon style={{fontSize: "65px"}}/> */}
            <AccountCircleOutlinedIcon style={{fontSize: "65px"}}/> 
            <p>{dataCookies?.SAMname ?? ""}</p>
            <button className="btn" onClick={logoutUser} style={{minWidth: '120px', maxWidth: '125px', height: '30px', paddingTop: '5px'}}>Logout</button>
            
          </div>
          <div style={{textAlign: "center", width: "170px", marginTop: "20px", marginBottom: "10px"}}>
            <a href="/admin">
              <button className="btn" style={{minWidth: '120px', maxWidth: '125px', height: '30px', paddingTop: '5px'}}>Admin Home</button>                          
            </a>
          </div>
          <a href="/admin/fillformAdmin">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#17a495"}}>
              Add new Faculty Projects
            </Button>
          </a>
          <a href="/admin/projects">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              All Projects
            </Button>
          </a>
          {/* <a href="/admin/generateform">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              Generate Link
            </Button>
          </a> */}
          <a href="/admin/student-projects">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              Students Projects
            </Button>
          </a>
          <a href="/admin/global-partnerships">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              MUSC International Partnerships
            </Button>
          </a>
          <a href="/admin/reports">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              Internal Reports
            </Button>
          </a>
          <a href="/admin/update-faculty-db">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              Update Former Faculty List
            </Button>
          </a>
          <a href="/admin/update-ui">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              Update UI Elements
            </Button>
          </a>
          
          {/* <a href="/admin/international-agreements">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              International Agreements
            </Button>
          </a> */}
          
          <a href="/admin/create-admin-users">
            <Button variant="contained" style={{ width: "170px", margin: "10px 10px", backgroundColor: "#00759E"}}>
              Manage Admin Users
            </Button>
          </a>
        </div>
        </> 
    );
}
 
export default SideMenu;
