import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Router />
    <Footer />
  </React.StrictMode>,
  rootElement
);
