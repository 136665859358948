import React, { useState, useEffect } from 'react'
import { geoPath, geoMercator } from 'd3-geo'
import { feature } from 'topojson-client'
import worldData from '../../data/world.json'
import { getCountWCountry } from './APIsClient'
const uuid = require('react-uuid')





let MapCountry = ({ name, geographies, d }) => {
    if (((geographies.countries).hasOwnProperty(name))) {
        
        var fillColor= ((geographies.countries)[name]) < 5 ? `#00759e` : ((geographies.countries)[name]) < 10 ? `#00759e` : `#00759e`;
        
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            console.log("hovered")
          setIsHovered(true);
        };
    
        const handleMouseLeave = () => {
          setIsHovered(false);
        };
    
        const outlineColor = isHovered ? '#0d4659' : 'white';
        const outlineWidth = isHovered ? 2 : 0;

        return <>
            <path

                key={`path-${uuid()}`}
                d={geoPath().projection(geoMercator())(d)}
                fill={ fillColor }
                stroke= {outlineColor}
                strokeWidth={outlineWidth}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >

                <title>
                    Country: {name}
                    {/* {`\n`}Projects : {`${(geographies.countries)[name]}`} */}
                </title>

            </path>
        </>
    } else {
        return <>
            <path

                key={`path-${uuid()}`}
                d={geoPath().projection(geoMercator())(d)}
                fill={`#A9A9A9`}
                stroke="white"
                strokeWidth={1}
            >

            </path>
        </>
    }
}
const WorldMapFaculty = () => {
    const [geographies, setGeographies] = useState({ "map_data": [], countries: {} })

    const componentDidMount = () => {
        getCountWCountry(1).then(data => {
            let temp= data["United States"];
            data["United States of America"]= temp;
            setGeographies(
                (prevState) => ({
                    ...prevState,
                    countries: data
                }))
        }).catch(e => {
            console.log("something went wrong", e);
        })

        try {
            const mapFeatures = ((feature(worldData, worldData.objects.countries))).features
            setGeographies({ ...geographies, "map_data": mapFeatures });

        } catch (error) {
            console.log("found error", error);
        }
    }

    useEffect(componentDidMount, [])
    return (
        <>
            <svg width="100%" height="auto" viewBox="0 -30 900 450">
                <g>
                    {(geographies.map_data).map((d, i) => (
                        <MapCountry name={d.properties.name} geographies={geographies} d={d} key={i} />
                    ))}
                </g>
            </svg>
        </>
    )
}
export default WorldMapFaculty