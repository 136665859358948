import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';


const cache = setupCache({
    maxAge: 5000 * 10
})

const api = axios.create({
    // adapter: cache.adapter
})

const baseURL = process.env.REACT_APP_BASE_URL;


const approveStatus = (id) => {
    
    return new Promise ( (resolve, reject)=>{
        api({
            url: `${baseURL}/setReview?token=${1}`,
            method: "post",
            data: {
              id,
              status: true,
            }
        }).then(({data})=>{
            resolve(data);
        }).catch(e=>{
            reject(e.message);
        })
    })
};


const getAllUnReviewed= ()=>{
    return new Promise( (resolve, reject)=>{
        api({
            url: `${baseURL}/getAllUnReviewed?token=${1}`,
            method: "get",
          }).then(({data}) => {
              if (data.error) reject (data.error);
                resolve(data.data); 
          }).catch(e=>{
            reject(e.message);
          });
    })
}


const getStudentsProjects= ()=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/getStudentProjects?token=${1}`,
            method: "get"
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message);
        })
    })
}

const getGlobalPartners= ()=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/getGlobalPartners?token=${1}`,
            method: "get"
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message);
        })
    })
}

const setGlobalPartners= (arr)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/setGlobalPartners?token=${1}`,
            method: "post",
            data: arr
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const setStudentProjects= (obj) =>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/setStudentProjects?token=${1}`,
            method: "post",
            data: obj
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const getDataCount= (obj)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/getDataCount?token=${1}`,
            method: "post",
            data: obj
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}


const setDataCount= (obj)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/setDataCount?token=${1}`,
            method: "post",
            data: obj
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const getReports= (reportId)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/getReports`,
            method: "post",
            data: {reportId: reportId}
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message);
        })
    })
}
const setFormerFaculty= (arr)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/setFormerFaculty?token=${1}`,
            method: "post",
            data: {formarFaculty: arr}
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}
const updateUIData= (homeIntroText, factData)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/updateUIData?token=${1}`,
            method: "post",
            data: {homeIntro: homeIntroText, facts:factData}
        }).then(({data})=>{
            if(data.error) reject(data.error);
            resolve(data.data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}
const getUIData= ()=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/UIData.json?token=${1}`,
            method: "get"
        }).then(({data})=>{
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const uploadImageAPI= (formData)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/uploadImage?token=${1}`,
            method: "post",
            data: formData,
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}
const setInternationalAgreements= (obj)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/setInternationalAgreements?token=${1}`,
            method: "post",
            data: obj,
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })   
}
const deleteProjectById= (obj)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/delete-project?token=${1}`,
            method: "post",
            data: obj,
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })   
}


const getFormerFaculties= ()=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/get-former-faculties?token=${1}`,
            method: "get",
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const getAdminUsers = ()=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/get-admin-users?token=${1}`,
            method: "get",
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const createAdminUser = (obj)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/create-admin-user?token=${1}`,
            method: "post",
            data: obj,
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}

const deleteAdminUser = (obj)=>{
    return new Promise((resolve, reject)=>{
        api({
            url: `${baseURL}/delete-admin-user?token=${1}`,
            method: "post",
            data: obj,
        }).then(({data})=>{
            if(data.error) reject(data.message);
            resolve(data);
        }).catch(e=>{
            reject(e.message)
        })
    })
}



export {
    approveStatus,
    getAllUnReviewed,
    getStudentsProjects,
    getGlobalPartners,
    setGlobalPartners,
    setStudentProjects,
    getDataCount,
    setDataCount,
    getReports,
    setFormerFaculty,
    updateUIData,
    getUIData,
    uploadImageAPI,
    setInternationalAgreements,
    deleteProjectById,
    getFormerFaculties,
    getAdminUsers,
    createAdminUser,
    deleteAdminUser
}