import React, { useState, useEffect } from "react";

import WorldMapCountry from '../components/MapComponents/WorldMapCountry';
import WorldMapFaculty from '../components/MapComponents/WorldMapFaculty';
import WorldMapStudents from '../components/MapComponents/WorldMapStudents';

import { Box, Grid, Card, CardActions, CardContent, Typography, Link, Button } from '@mui/material';
import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { getDataCount, getUIData } from "../components/Admin/APIsClient";
import PublicIcon from '@mui/icons-material/Public';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import AddchartIcon from '@mui/icons-material/Addchart';


export default function HomeScreen({ data, ...props }) {

    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [activeMap, setActiveMap]= useState(1)

    const [introText, setIntroText]= useState(`Every year, the Center for Global Health helps to fund numerous research and clinical projects across the world for 
                                                MUSC faculty, students and trainees. Engage with the map below to learn more about where MUSC is making a 
                                                difference across the globe.`
                                            );


    const [facts, setFacts]= useState({
        fact1: {
           title: "",
           data: "" 
        },
        fact2: {
            title: "",
            data: "" 
        },
        fact3: {
           title: "",
           data: "" 
        }
    })

    useEffect(() => {
        document.title="Our Global Health Impact - Map Homepage";

        getUIData().then(res=>{
            setIntroText(res.homeIntro);
            setFacts(res.facts)
        }).catch(e=>{
            console.log(e);
        })
    }, []);

    return (
        <Box className="boundedContainer">
            <h1 className= "h1">Our Global Health Impact</h1>
            <Typography style= {{color: "#00447c" }} className= "text">
                {introText}
                Dive further into the database, <a href="/projects" style={{textDecoration: "underline", fontWeight: 600}}>click here to explore all global projects</a>.
            </Typography>
            {/* <Box style= {{display: "flex", width: "100%"}}> */}
                
            {/* </Box> */}
            <Box sx={{ width: '100%', typography: 'body1', margin: "30px auto" }}>
                <Box style={{display: "flex", justifyContent: "space-around", flexDirection: "row"}}>
                    <button onClick={()=> setActiveMap(1)} className={(activeMap == 1)? "selectiveButton selectiveButtonActive": "selectiveButton"}>Faculty Projects</button>
                    <button onClick={()=> setActiveMap(2)} className={(activeMap == 2)? "selectiveButton selectiveButtonActive": "selectiveButton"}>Student & Trainee Projects</button>
                    <button onClick={()=> setActiveMap(3)} className={(activeMap == 3)? "selectiveButton selectiveButtonActive": "selectiveButton"}>International Community at MUSC</button>
                </Box>
                <Box>
                    {(activeMap == 1)? <WorldMapFaculty /> : ((activeMap == 2)? <WorldMapStudents /> : <WorldMapCountry />)}
                </Box>
            </Box>

            <Box style={{display: "flex", flexWrap: 'wrap', width: "100%", justifyContent: 'space-between', margin: "40px 0"}}>
                    <a href="https://web.musc.edu/about/global-health" className= "mainButton">
                        <PublicIcon/>
                        <p>Center for Global Health</p>
                    </a>
                    <a href="/projects" className= "mainButton">
                        <HorizontalSplitIcon/>
                        <p>Explore Our Reach</p>
                    </a>
                    <a href="/admin/fillformAdmin" className= "mainButton">
                        <AddchartIcon/>
                        <p>New Faculty Projects</p>
                    </a>
            </Box>
            {/* <Box style={{maxWidth: "1000px", marginBottom: "25px"}}>
                <img src="/images/MUSC-fact.jpg" width="100%"/>
            </Box> */}
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4} style={{textAlign: "center"}}>
                        <Typography variant="h3" component="h2" style={{color: "#00447c", fontWeight: 600}}>
                            {facts.fact1.data}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: "#00447c"}}>
                            {facts.fact1.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} style={{textAlign: "center", padding: "ca"}}>
                        <Typography variant="h3" component="h2" style={{color: "#00447c", fontWeight: 600}}>
                            {facts.fact2.data}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: "#00447c"}}>
                            {facts.fact2.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} style={{textAlign: "center"}}>
                        <Typography variant="h3" component="h2" style={{color: "#00447c", fontWeight: 600}}>
                            {facts.fact3.data}
                        </Typography>
                        <Typography variant="body1" component="p" style={{color: "#00447c"}}>
                            {facts.fact3.title}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
