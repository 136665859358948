import React, { useState, useEffect } from "react";
import { deleteProjectById, getAllUnReviewed } from './APIsClient';
import UnreviewFromList from './unreviewFromList';
import { Grid, Box, Table, TableBody, TableHead, TableRow, TableCell, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@mui/material'
import Breadcrumbs from "../Layout/Breadcrumbs"
import SideMenu from  "./SideMenu";


export default function AdminPage() {

  const [reviewForms, setReviewForms] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteAbleProject, setDeleteAbleProject] = useState(null);
  const componentDidMount= ()=>{

    document.title= "Our Global Health Impact - Admin Dashboard"

    getAllUnReviewed().then(data=>{
      setReviewForms(data);
    }).catch(e=>{
      console.log(e);
    })
  }


  useEffect(componentDidMount, []);

  const deleteProject = (id) => {
    setDeleteAbleProject(id);
    setShowDeletePopup(true);
  };

  const deleteApproved = () => {
    setShowDeletePopup(false);
    let id = deleteAbleProject;
    deleteProjectById({id: id}).then(data=>{
      $("#" + id).remove();
    }).catch(e=>{
      console.log(e);
    })
  }

  return (
    
    <Box style={{maxWidth: "1000px", margin:"0 auto"}}>
    <Breadcrumbs customTitle={"Admin Dashboard"}/>

    <Grid container style={{width: "100%"}}>

      
      <Grid item sm={3} xs={12}>  
        <SideMenu/>
      </Grid>
      <Grid item sm={9} xs={12}>
        <div style={{ textAlign: "center" }}>
          {/* Section for Header  */}

          <h1 style={{fontSize: "2.2rem", color: "#00447C", margin: "20px auto", fontFamily: "Arial,sans-serif", textAlign: "center"}}>
            {" "}
            Global Health Centre Internal Database
          </h1>
          <br />

          {/* Section for Unreviewed Forms  */}
          <h2 style={{fontSize: "1.8rem", color: "#00447C", margin: "5px 20px 5px 20px", fontFamily: "Arial,sans-serif", textAlign: "center"}}>Review Submissions</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid black",
                  }}
                >
                  Project Title
                </TableCell>   
                <TableCell
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid black",
                  }}
                >
                  Approve
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid black",
                  }}
                >
                  Edit
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    borderBottom: "1px solid black",
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {(reviewForms && (
                <>
                  {reviewForms.map((object, i) => (
                    <>
                      <UnreviewFromList obj={object} k={i} deleteProject={deleteProject} />
                    </>
                  ))}
                </>
              )) || (
                <>
                  <TableRow><TableCell>No submissions for review</TableCell></TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </Grid>
    <Dialog open={showDeletePopup}>
      <DialogTitle>
        Are you sure you want to delete this project?
      </DialogTitle>
      <DialogContent>
        <p>
          Once a project is deleted it cannot be recovered.
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={deleteApproved}>Delete</Button>
        <Button variant="contained" color="primary" onClick={()=> setShowDeletePopup(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
}
// render(<App />, document.getElementById('root'));
