import React, { useEffect, useState } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { getProject } from "./APIsClient";
import CircularProgress from '@mui/material/CircularProgress';
const secret = new TextEncoder().encode("w498w34bw3ef7gqfnalrrgat");
import { Grid, Box } from "@mui/material";

import Breadcrumbs from "../Layout/Breadcrumbs";

const createList = (items) => {
  return items.map((item, index) => (
    <span key={index}>
      {item}
      {index !== items.length - 1 && ", "}
    </span>
  ));
};


export default function Detail() {
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BASE_URL;

  const [formData, setFormData] = useState({
    data: {
      Associated_Faculty: [],
      Global_Health_Topic: [],
      Dates: "",
      Project: "",
      Description: "",
      countries: [],
      loaded: false,
    },
  });
  const { url } = useParams();

  const componentDidMount= ()=>{
    getProject(url).then(data=>{
      if(data.error) navigate("/page-not-found");
      setFormData({ data: data.data, loaded: false });
      document.title= "Our Global Health Impact - " + data.data.Project;
    }).catch(e=>{
      console.log(e);
    })
  }



  useEffect(componentDidMount, []);

  return (
    <>
      {/* <Header /> */}

      {formData.data.loaded ? (<div className="spinner-container"> <CircularProgress /></div>) : (
      <Box style={{maxWidth: "1000px", margin: "auto"}}>
      <Breadcrumbs customTitle={formData.data.Project}/>
      <Grid container spacing={2} style={{ margin: "15px auto 15px", border: '2px solid #00447c' }}>
        <Grid item sm={4} style={{backgroundColor: "#f2f2f2"}}>
          <div style={{paddingTop: ".5rem", fontSize: "0.95rem" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6" style={{ }}>
                  <div className="list-group" />
                  <div className="list-group" />
                  <p className="text-start" style={{ margin: "0px 10px 5px 23px", height: "auto" }}>
                    {formData.data.Associated_Faculty[0] && formData.data.Associated_Faculty[0].trim() && (
                      <>
                        <strong>
                          <span style={{ color: "rgb(66, 88, 101)" }}>FACULTY:&nbsp;{" "}</span>
                        </strong>
                        <br />
                        <strong>
                          <span style={{ color: "rgb(7, 81, 149)", backgroundColor: "transparent" }}>
                          
                          {formData.data.Associated_Faculty.map((faculty, index) => (
                            <a key={index} href={`/projects?faculty=${faculty}`}>
                              {faculty}
                              {index !== formData.data.Associated_Faculty.length - 1 && ", "}
                            </a>
                          ))}
                          </span>
                        </strong>
                      </>
                    )}
                  </p>
                  <p className="text-start" style={{ margin: "5px 23px", height: "auto" }}>
                    {formData.data.Global_Health_Topic && (
                      <>
                        <strong>
                          <span style={{ color: "rgb(66, 88, 101)" }}>TOPICS:</span>
                        </strong>
                        <br />
                        <strong>
                          <span style={{ color: "rgb(7, 81, 149)", backgroundColor: "transparent" }}>
                            {formData.data.Global_Health_Topic.map((topic, index) => (
                            <a key={index} href={`/projects?topic=${encodeURIComponent(topic)}`}>
                              {topic}
                              {index !== formData.data.Global_Health_Topic.length - 1 && ", "}
                            </a>
                          ))}
                          </span>
                        </strong>
                      </>
                    )}
                  </p>
                  <p className="text-start" style={{ margin: "5px 24px", height: "auto" }}>
                    {formData.data.countries && (
                      <>
                        <strong>
                          <span style={{ color: "rgb(66, 88, 101)" }}>COUNTRIES:</span>
                        </strong>
                        <br />
                        <strong>
                          {formData.data.countries.map((country, index) => (
                            <a key={index} href={`/projects?country=${country}`}>
                              {country}
                              {index !== formData.data.countries.length - 1 && ", "}
                            </a>
                          ))}
                        </strong>
                      </>
                    )}
                  </p>
                  { Boolean(formData.data.Sponsor) &&
                    <p className="text-start" style={{ margin: "20px 23px", height: "auto" }}>
                    <strong>
                      <span style={{ color: "rgb(66, 88, 101)" }}>FUNDING SPONSOR:</span>
                    </strong>
                    <br />
                    <span style={{ color: "rgb(66, 88, 101)" }}>{formData.data.Sponsor}</span>
                  </p>}
                  { Boolean(formData.data.International_Partner) &&
                    <p className="text-start" style={{ margin: "5px 23px", height: "auto" }}>
                    <strong>
                      <span style={{ color: "rgb(66, 88, 101)" }}>INTERNATIONAL PARTNER:</span>
                    </strong>
                    <br />
                    <span style={{ color: "rgb(66, 88, 101)" }}>{formData.data.International_Partner}</span>
                  </p>}
                  { Boolean(formData.data.Dates !== "Not Avilable"  && new Date(formData.data.Start_Date).getFullYear()>2000 && formData.data.End_Date !== "") &&
                  <p className="text-start" style={{ margin: "5px 23px", height: "auto" }}>
                    <strong>
                      <span style={{ color: "rgb(66, 88, 101)" }}>START DATE:</span>
                    </strong>
                    <br />
                    <span style={{ color: "rgb(66, 88, 101)" }}>{((new Date(formData.data.Start_Date).getMonth()+1 <10)? "0"+(new Date(formData.data.Start_Date).getMonth() +1) : new Date(formData.data.Start_Date).getMonth()+1)+"/" + new Date(formData.data.Start_Date).getFullYear() }</span>
                  </p>}
                  { Boolean(formData.data.End_Date && formData.data.End_Date !== "" && new Date(formData.data.End_Date).getFullYear()>2000 ) &&
                  <p className="text-start" style={{ margin: "5px 23px", height: "auto" }}>
                    <strong>
                      <span style={{ color: "rgb(66, 88, 101)" }}>END DATE:</span>
                    </strong>
                    <br />
                    <span style={{ color: "rgb(66, 88, 101)" }}>{((new Date(formData.data.End_Date).getMonth()+1 <10)? "0"+(new Date(formData.data.End_Date).getMonth() +1) : new Date(formData.data.End_Date).getMonth() +1)+"/" + new Date(formData.data.End_Date).getFullYear()}</span>
                  </p>}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item sm={8} sx={{padding: "20px"}}>
              <span style={{ color: "#00447c", fontSize: "1.15rem", fontFamily: "Arial,sans-serif", fontWeight: "700"}}>{formData.data.Project}</span>
          <div className="col">
            <br />
            <p className="text-start">
                <span style={{ color: "rgb(28, 41, 49)", fontFamily: "Arial,sans-serif" }}>{formData.data.Description}</span>
            </p>
          </div>
        </Grid>
      </Grid>
      </Box>)}

      {/* <Footer /> */}
    </>
  );
}
