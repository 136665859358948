
import React from "react";
// import "./css/enterprise.css";
export default function Footer() {
    return (
        <div><footer className="l-footer" id="footer">
            <div className="footer-top">
                <div className="footer-top__inner">
                    <div className="footer-top__phone">
                        Contact Us{" "}
                        <a className="footer-top__phone-link" href="tel:18437922300">
                            843-792-2300
                        </a>
                    </div>
                    <nav className="social" role="navigation" aria-label="Social Media Links">
                        <ul className="social__list">
                            <li className="social__item">
                                <a
                                    href="https://www.facebook.com/MUSCCharleston"
                                    className="social__link"
                                >
                                    <span className="show-for-sr">Facebook social link</span>
                                    <svg className="icon icon-facebook">
                                        <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-facebook" />
                                    </svg>
                                </a>
                            </li>
                            <li className="social__item">
                                <a
                                    href="https://twitter.com/muscatalystnews"
                                    className="social__link"
                                >
                                    <span className="show-for-sr">Twitter social link</span>
                                    <svg className="icon icon-twitter">
                                        <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-twitter" />
                                    </svg>
                                </a>
                            </li>
                            <li className="social__item">
                                <a href="https://muschealth.org/blog" className="social__link">
                                    <span className="show-for-sr">Blog social link</span>
                                    <svg className="icon icon-blog">
                                        <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-blog" />
                                    </svg>
                                </a>
                            </li>
                            <li className="social__item">
                                <a
                                    href="https://www.instagram.com/muschealth/"
                                    className="social__link"
                                >
                                    <span className="show-for-sr">Instagram social link</span>
                                    <svg className="icon icon-instagram">
                                        <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-instagram" />
                                    </svg>
                                </a>
                            </li>
                            <li className="social__item">
                                <a
                                    href="https://www.youtube.com/user/muschealth"
                                    className="social__link"
                                >
                                    <span className="show-for-sr">Youtube social link</span>
                                    <svg className="icon icon-youtube">
                                        <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-youtube" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="footer-middle">
                <div className="skip row">
                    <a className="skip__link u-visuallyhidden focusable" href="#back-to-top">
                        Skip Footer Utility Navigation
                    </a>
                </div>
                <nav
                    className="footer-middle__inner"
                    role="navigation"
                    aria-label="Footer Primary Navigation"
                >
                    {/*Pulls in Global Footer Sections if any are selected on the Site Footer */}
                    <div className="footer-middle__column">
                        <h3 className="footer-middle__title">About MUSC</h3>
                        <ul className="footer-middle__links">
                            <li className="footer-middle__item">
                                <a
                                    href="https://musc.career-pages.com/jobs/search"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Careers
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/patients-visitors/contact"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://web.musc.edu/about/staff-directory"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Find a Person
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://giving.musc.edu/"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Giving
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a href="https://web.musc.edu/about/a-to-z" className="footer-middle__link" title="">
                                    MUSC A to Z
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://web.musc.edu/about/leadership/institutional-offices/communications/pamr"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Public Affairs &amp; Media Relations
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://web.musc.edu/resources/health-and-wellness/wellness-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Wellness Center
                                </a>{" "}
                            </li>
                        </ul>
                    </div>
                    <div className="footer-middle__column">
                        <h3 className="footer-middle__title">Education</h3>
                        <ul className="footer-middle__links">
                            <li className="footer-middle__item">
                                <a
                                    href="https://education.musc.edu/admissions"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Apply
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://education.musc.edu/alumni"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Alumni
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://education.musc.edu/admissions/current-applicants/check-application-progress"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Check My Application
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://education.musc.edu/colleges"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Colleges
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://education.musc.edu/programs"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Degrees &amp; Programs
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://www.library.musc.edu/"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    MUSC Libraries
                                </a>{" "}
                            </li>
                        </ul>
                    </div>
                    <div className="footer-middle__column">
                        <h3 className="footer-middle__title">Research &amp; Innovation</h3>
                        <ul className="footer-middle__links">
                            <li className="footer-middle__item">
                                <a
                                    href="https://research.musc.edu/clinical-trials"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Clinical Trials
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://research.musc.edu/about/administration/support-offices"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Research Offices
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://research.musc.edu/resources/sctr"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    SCTR
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://web.musc.edu/innovation/the-zucker-institute-for-innovation-commercialization"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Technology Transfer
                                </a>{" "}
                            </li>
                        </ul>
                    </div>
                    <div className="footer-middle__column">
                        <h3 className="footer-middle__title">Patient Care</h3>
                        <ul className="footer-middle__links">
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/patients-visitors/appointments"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Schedule an Appointment
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/medical-services"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Adult Medical Services
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://musckids.org/our-services"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Pediatric Medical Services
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://answers.muschealth.org/provider.html"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Find a Provider
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Locations
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/patients-visitors"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Patients &amp; Visitors
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/patients-visitors/billing"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Patient Billing
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/health-professionals"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Health Professionals
                                </a>{" "}
                            </li>
                        </ul>
                    </div>
                    <div className="footer-middle__column">
                        <h3 className="footer-middle__title">Medical Centers</h3>
                        <ul className="footer-middle__links">
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations/university-medical-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Charleston
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations/chester-medical-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Chester
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/columbia-medical-center-downtown"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Columbia Downtown
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/columbia-medical-center-northeast"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Columbia Northeast
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/medical-services/emergency/fairfield"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Fairfield Emergency
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations/florence-medical-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Florence
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/kershaw-medical-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Kershaw
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations/lancaster-medical-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Lancaster
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations/marion-medical-center"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    Marion
                                </a>{" "}
                            </li>
                            <li className="footer-middle__item">
                                <a
                                    href="https://muschealth.org/locations"
                                    className="footer-middle__link"
                                    title=""
                                >
                                    All Locations
                                </a>{" "}
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="footer-middle__top-link">
                        <span className="show-for-sr">Jump back to top of page</span>
                        <svg className="icon icon-circle-arrow-w">
                            <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-circle-arrow-w" />
                        </svg>
                    </a>
                </nav>
            </div>
            <div className="footer-ctas">
                <div className="quick-links ">
                    <a
                        className="quick-link module"
                        href="https://muschealth.org/patients-visitors/about-us/confidential-hotline"
                        target=""
                    >
                        <svg className="icon icon-phone">
                            <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-phone" />
                        </svg>
                        <div className="quick-link__title">Confidential Hotline</div>
                    </a>
                    <a
                        className="quick-link module"
                        href="https://muschealth.org/patients-visitors/contact/liaison"
                        target=""
                    >
                        <svg className="icon icon-contact">
                            <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-contact" />
                        </svg>
                        <div className="quick-link__title">Patient Complaint or Concern</div>
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom__inner">
                    <div className="footer-bottom__top">
                        <span className="footer-bottom__logo right">
                            <svg className="icon icon-musc-university-solid">
                                <use xlinkHref="/images/assets/svg/musc-svg-sprite.svg#icon-musc-university-solid" />
                            </svg>
                        </span>
                    </div>
                    <div className="footer-bottom__upper" role="contentinfo">
                        <span className="footer-bottom__copyright">
                            © Medical University of South Carolina
                        </span>
                        <address className="footer-bottom__address">
                            171 Ashley Avenue, Charleston, SC 29425
                        </address>
                    </div>
                    <div className="footer-bottom__lower">
                        <ul
                            className="footer-bottom__links"
                            role="navigation"
                            aria-label="Footer Secondary Navigation"
                        >
                            <li className="footer-bottom__item">
                                <a
                                    href="https://web.musc.edu/about/compliance/disclaimer"
                                    className="footer-bottom__link"
                                    title=""
                                >
                                    {" "}
                                    Disclaimer
                                </a>
                            </li>
                            <li className="footer-bottom__item">
                                <a
                                    href="https://web.musc.edu/about/compliance/privacy"
                                    className="footer-bottom__link"
                                    title=""
                                >
                                    {" "}
                                    Privacy Policy
                                </a>
                            </li>
                            <li className="footer-bottom__item">
                                <a
                                    href="https://web.musc.edu/about/compliance/accessibility"
                                    className="footer-bottom__link"
                                    title=""
                                >
                                    {" "}
                                    Web Accessibility Statement
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    );
}