import {
  BrowserRouter as DomRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import React, { lazy } from "react";
import SuspenseContainer from "./components/Layout/SuspenseContainer";
import E404Screen from "./screens/E404Screen";
import HomeScreen from "./screens/HomeScreen";
import AdminScreen from "./screens/AdminScreen";
import ProjectDetailsScreen from "./screens/ProjectDetailsScreen";
const ProjectsScreen = lazy(() => import("./screens/ProjectsScreen"));
const LoginScreen = lazy(() => import("./screens/LoginScreen"));
const FormsScreen = lazy(() => import("./screens/FormsScreen"));
const GenerateForms = lazy(() => import("./components/Forms/GenerateForms"));
const Reports = lazy(() => import("./components/Admin/Reports"));
const StudentProjects= lazy(()=> import("./components/Admin/StudentProjects"));
const GlobalPartnerships= lazy(()=> import("./components/Admin/GlobalPartnerships"));
const UpdateFacultyDB= lazy(()=> import("./components/Admin/UpdateFacultyDB"));
const UpdateUI= lazy(()=> import("./components/Admin/UpdateUI"));
const InternationalAgreements= lazy(()=> import("./components/Admin/InternationalAgreements"));
const CreateAdminUsers=  lazy(()=> import("./components/Admin/CreateAdminUsers"));

import Cookies from "js-cookie";
import { isExpired, decodeToken,  } from "react-jwt";



function ProtectedRoute({component: Component, access: access,...rest}) {
  var cookies;
  var dataCookies;
  
  try{
    cookies= Cookies.get("jwt");
    dataCookies= decodeToken(cookies);
  }catch(err){
    console.log(err)
    window.location.href= "/login";
    return <><p>Redirecting...</p></>
  }
  if (cookies && !isExpired(cookies)) {
    if((access==2 || dataCookies.access == 1)){
      return (<SuspenseContainer>
        <Component {...rest} />;
      </SuspenseContainer>)
    }else{
      return <Navigate to="/admin/fillformAdmin" />;
    }
  } else {
    
    window.location.href= "/login";
    return <><p>Redirecting</p></>
  }
}

function Router() {
  return (
    <DomRouter>
      <Routes>
        
        <Route
          exact
          path="/"
          element={
            <SuspenseContainer>
              <HomeScreen />
            </SuspenseContainer>
          }
        />

        <Route
          exact
          path="/projects"
          element={
            <SuspenseContainer>
              <ProjectsScreen />
            </SuspenseContainer>
          }
        />

        <Route
          exact
          path="/admin"
          element={
              <ProtectedRoute access={1} component={AdminScreen}/>
          }
        />
        {/* <Route
          exact
          path="/login"
          element={<SuspenseContainer><LoginScreen /></SuspenseContainer>}
        /> */}
        <Route
          exact
          path="/projects/:url"
          element={
            <SuspenseContainer>
              <ProjectDetailsScreen />
            </SuspenseContainer>
          }
        />

        <Route
          exact
          path="/admin/editform"
          element={
            <ProtectedRoute access={1} component={FormsScreen}/>
          }
        />
        <Route
          exact
          path="/admin/editform/:id"
          element={
            <ProtectedRoute access={1} component={FormsScreen}/>
          }
        />
        <Route
          exact
          path="/admin/fillform"
          element={
            <ProtectedRoute access={1} component={FormsScreen}/>
          }
        />
        <Route
          exact
          path="/admin/fillformAdmin"
          element={
            <ProtectedRoute component={FormsScreen} access={2}/>
          }
        />
        <Route
          exact
          path="/admin/generateform"
          element={
            <ProtectedRoute access={1} component={GenerateForms}/>
          }
        />
        <Route
          exact
          path="/admin/reports"
          element={
            <ProtectedRoute access={1} component={Reports}/>
          }
        />
        <Route
          exact
          path="/admin/student-projects"
          element={
            <ProtectedRoute access={1} component={StudentProjects}/>
          }
        />
        <Route
          exact
          path="/admin/global-partnerships"
          element={
            <ProtectedRoute access={1} component={GlobalPartnerships}/>
          }
        />
        <Route
          exact
          path="/admin/update-faculty-db"
          element={
            <ProtectedRoute access={1} component={UpdateFacultyDB}/>
          }
        />
        <Route
          exact
          path="/admin/update-ui"
          element={
            <ProtectedRoute access={1} component={UpdateUI}/>
          }
        />
        <Route
          exact
          path="/admin/projects"
          element={
            <ProtectedRoute access={1} component={ProjectsScreen} isAdmin={true}/>
          }
        />
        {/* <Route
          exact
          path="/admin/international-agreements"
          element={
            <ProtectedRoute access={1} component={InternationalAgreements}/>
          }
        /> */}

        <Route
          exact
          path="/admin/create-admin-users"
          element={
            <ProtectedRoute access={1} component={CreateAdminUsers} isAdmin={true}/>
          }
        />
        <Route
          path="*"
          element={
            <SuspenseContainer>
              <E404Screen />
            </SuspenseContainer>
          }
        />
       
      </Routes>
    </DomRouter>
  );
}

export default Router;
